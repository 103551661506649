<template>
    <el-container>
        <LayoutAside
            :menu="menuList"
            background="#191a23"
            :logo="require('../../assets/img/logo-new.png')"
            text-color="#ffffff"
        ></LayoutAside>
        <el-container direction="vertical">
            <LayoutHeader />
            <LayoutMain>
                <router-view />
            </LayoutMain>
        </el-container>
    </el-container>
</template>

<script setup>
import LayoutAside from "./LayoutAside.vue";
import LayoutHeader from "./LayoutHeader.vue";
import LayoutMain from "./LayoutMain.vue";
import { onMounted, ref } from "vue";

// 菜单列表
let menuList = ref([]);

onMounted(() => {
    menuList.value = [
        {
            title: "在线电杆管理",
            icon: "icon-wifi",
            index: "online",
            children: [
                {
                    title: "在线电杆管理",
                    index: "/equipment/online/list"
                },
                {
                    title: "在线电杆定位",
                    index: "/equipment/map"
                }
            ]
        },
        {
            title: "智能终端设备管理",
            icon: "icon-dashboard",
            index: "terminal",
            children: [
                {
                    title: "智能终端设备管理",
                    index: "/equipment/terminal/list"
                }
            ]
        },
        {
            title: "用户管理",
            icon: "icon-user",
            index: "user",
            children: [
                {
                    title: "用户管理",
                    index: "/user/list"
                },
                {
                    title: "角色管理",
                    index: "/role/list"
                },
                {
                    title: "权限管理",
                    index: "/permission/list"
                }
            ]
        },
        {
            title: "序列号管理",
            icon: "icon-orderedlist",
            index: "serial_number",
            children: [
                {
                    title: "生产厂家",
                    index: "/serial_number/list/factory"
                },
                {
                    title: "资产管理",
                    index: "/serial_number/list/assets"
                },
                {
                    title: "规则管理",
                    index: "/serial_number/rule/list"
                }
            ]
        },
        {
            title: "客户资产",
            icon: "icon-wallet",
            index: "asset",
            children: [
                {
                    title: "电杆资产",
                    index: "/asset/wire_pole/list"
                }
            ]
        },
        {
            title: "货物管理",
            icon: "icon-shop",
            index: "goods",
            children: [
                {
                    title: "发货管理",
                    index: "/goods/send/list"
                },
                {
                    title: "收货管理",
                    index: "/goods/receive/list"
                },
                {
                    title: "安装管理",
                    index: "/goods/install/list"
                },
                {
                    title: "售后管理",
                    index: "/goods/after_sale/list"
                },
                {
                    title: "审批管理",
                    index: "/goods/approval/list"
                }
            ]
        },
        {
            title: "报警管理",
            icon: "icon-alert",
            index: "alarm",
            children: [
                {
                    title: "报警管理",
                    index: "/alarm/list"
                }
            ]
        },
        {
            title: "巡更管理",
            icon: "icon-cloud-sync",
            index: "patrol",
            children: [
                {
                    title: "巡更管理",
                    index: "/patrol/list"
                }
            ]
        }
    ];
});
</script>
