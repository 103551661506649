<template>
    <el-aside :style="{ background: background }" :width="collapse ? '64px' : '240px'">
        <div class="logo">
            <img :src="logo" draggable="false" />
            <transition name="fade">
                <span v-if="!collapse" :style="{ color: textColor }">浙江永达</span>
            </transition>
        </div>
        <el-menu
            :background-color="background"
            :collapse="collapse"
            :collapse-transition="false"
            :default-active="$route.path"
            :text-color="textColor"
            default-active="2"
            router
        >
            <el-sub-menu v-for="(item, index) in menu" :index="item.index">
                <template #title>
                    <i :class="item.icon" class="menu-icon iconfont"></i>
                    <span>{{ item.title }}</span>
                </template>
                <el-menu-item v-for="(citem, cindex) in item.children" :index="citem.index">
                    {{ citem.title }}
                </el-menu-item>
            </el-sub-menu>
        </el-menu>
        <a class="collapse-button" @click="collapse = !collapse">
            <i v-if="collapse" class="iconfont icon-indent"></i>
            <i v-else class="iconfont icon-outdent"></i>
        </a>
    </el-aside>
</template>

<script setup>
import { ref } from "vue";

let props = defineProps({
    background: {
        type: String,
        default: "#191a23"
    },
    textColor: {
        type: String,
        default: "#ffffff"
    },
    logo: {
        type: String
    },
    menu: {
        type: Array
    }
});

// 侧栏折叠
let collapse = ref(false);
</script>

<style lang="scss" scoped>
.el-aside {
    transition: width 0.3s ease;

    .logo {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 60px;
        padding: 15px;

        img {
            max-width: 100%;
            max-height: 100%;
        }

        span {
            display: inline-block;
            margin-left: 10px;
            font-size: 20px;
        }
    }

    .el-menu {
        height: calc(100vh - 120px);
        border-right: none !important;
        overflow-y: auto;
        overflow-x: hidden;

        .menu-icon {
            display: inline-block;
            vertical-align: middle;
            width: 24px;
            line-height: 1;
            margin-right: 5px;
            text-align: center;
            font-size: 20px;
        }
    }

    .el-menu--collapse {
        .menu-icon {
            margin: 0;
        }
    }

    .collapse-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 60px;
        background: #000;
        cursor: pointer;

        i {
            font-size: 20px;
            color: #ffffff;
        }
    }
}

// logo过渡动画
.fade-enter-active {
    transition: opacity 0.3s ease;
    transition-delay: 0.1s;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
