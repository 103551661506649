<template>
    <el-header>
        <el-breadcrumb separator="/">
            <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
            <el-breadcrumb-item v-for="(item, index) in $route.meta.breadcrumb">{{ item }}</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="user-container">
            <el-dropdown @command="onCommand">
                <a class="user-info" v-if="$store.getters.getUserData">
                    <!--<el-avatar :size="30" fit="cover" :src="$store.getters.getUserData.name.avatar" />-->
                    <span>{{ $store.getters.getUserData.name }}</span>
                </a>
                <template #dropdown>
                    <el-dropdown-menu>
                        <!--<el-dropdown-item>-->
                        <!--    <i class="iconfont icon-user"></i>-->
                        <!--    <span>个人中心</span>-->
                        <!--</el-dropdown-item>-->
                        <!--<el-dropdown-item>-->
                        <!--    <i class="iconfont icon-setting"></i>-->
                        <!--    <span>个人设置</span>-->
                        <!--</el-dropdown-item>-->
                        <el-dropdown-item command="logout">
                            <i class="iconfont icon-logout"></i>
                            <span>退出登录</span>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
        </div>
    </el-header>
</template>

<script setup>
import usePublic from "@/hooks/usePublic";

let { $route, $router, $store } = usePublic();

let onCommand = (type) => {
    if (type === "logout") {
        $router.push({ name: "Login" });
        $store.commit("delToken");
    }
};
</script>

<style lang="scss" scoped>
.el-header {
    display: flex;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(#000000, 0.1);

    .user-container {
        display: flex;
        align-items: center;
        margin-left: auto;

        .user-info {
            display: flex;
            align-items: center;
            cursor: pointer;

            span {
                font-size: 14px;
                color: #333;
                margin-left: 5px;
            }
        }
    }
}
</style>
