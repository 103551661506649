<template>
    <div class="navigation-list" v-if="$store.getters.getNavigation && $store.getters.getNavigation.length > 0">
        <el-tag
            v-for="(item, index) in $store.getters.getNavigation"
            :closable="$store.getters.getNavigation.length > 1"
            effect="plain"
            :type="$route.name !== item.name ? 'info' : ''"
            @click="navTo(item)"
            @close="delNav(item)"
        >
            {{ item.title }}
        </el-tag>
        <el-tag
            class="close-button"
            @click="delAllNav"
            v-if="$store.getters.getNavigation.length > 1"
            effect="plain"
            type="danger"
        >
            关闭全部
        </el-tag>
    </div>
</template>

<script setup>
import usePublic from "../../../../hooks/usePublic";

let { $route, $router, $store } = usePublic();

// 历史导航
let navTo = (item) => {
    $router.push({ name: item.name });
};

let delNav = (item) => {
    let nav = $store.getters.getNavigation.filter((citem) => {
        return item !== citem;
    });
    $store.commit("setNavigation", nav);
    if ($route.name === item.name) {
        navTo(nav[0]);
    }
};

// 删除全部
let delAllNav = () => {
    let nav = $store.getters.getNavigation.filter((item, index) => {
        return index === 0;
    });
    $store.commit("setNavigation", nav);
    navTo(nav[0]);
};
</script>

<style lang="scss" scoped>
.navigation-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .el-tag {
        margin-right: 10px;
        margin-bottom: 10px;
        cursor: pointer;
    }

    .close-button {
        margin-left: auto;
    }
}
</style>
